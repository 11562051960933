<template>
  <div id="online_courses_mycourses" class="mycourses mycourses_info online_courses_public">
    <PageSubHeader :pageSubHeader="pageSubHeader" :key="componentKey" />

    <section class="course_title">
      <div class="w1400 bg-deco">
        <img class="bg-deco-img" src="@/statics/img/index/deco_heart_r.png" alt="">
      </div>
      <div class="w1100">
        <h3 class="title txt-bold">{{ course.name }}</h3>
        <div class="course_info">
          <div class="detail">
            <p class="episode"><small>{{ course.video_count }}集</small></p>
            <template v-if="course.fraction_a || course.fraction_b">
              <div class="fraction_box">
                <p class="small type-A" v-if="course.fraction_a && course.fraction_a !== 0">
                  <span class="tag">A類</span>
                  <span class="credit">．{{ course.fraction_a }}學分</span>
                </p>
                <p class="small type-B" v-if="course.fraction_b && course.fraction_b !== 0">
                  <span class="tag">B類</span>
                  <span class="credit">．{{ course.fraction_b }}學分</span>
                </p>
              </div>
            </template>
            <!-- <template v-else> -->
              <p class="small">
                <span class="update_date small">研習證明：{{ course.hours }}小時</span>
              </p>
            <!-- </template> -->
          </div>
          <p class="video_length">課程總時長: {{ course.length }}</p>
          <p class="period">
            觀看/測驗期限: {{ computedExpiryTime }}
          </p>
        </div>
      </div>
    </section>

    <div class="course_description">
      <div class="w1100">
        <div class="column_list_box">

          <div class="column_list" v-if="course.content">
            <div class="main_title h4 txt-light_green txt-bold">課程說明</div>
            <div class="wrap">
              <div class="col100">
                <div class="content_group">
                  <div class="content txt-dark_green">
                    <p v-html="course.content"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column_list" v-if="isFractionExist">
            <div class="main_title h4 txt-light_green txt-bold">積分類型</div>
            <div class="content_group_box">
              <div class="content_group"
                v-for="(value, key, index) in course.fractions"
                :key="key"
                v-show="value * 1 !== 0"
              >
                <div class="title txt-bold">{{ fractionsNameArr[index] }}</div>
                <div class="content txt-dark_green">{{ value || 0 }}點</div>
              </div>
            </div>
          </div>

          <div class="column_list" v-if="course.price">
            <div class="main_title h4 txt-light_green txt-bold">報名資訊</div>
            <div class="content_group_box">
              <div class="content_group"
                v-for="(value, key, index) in course.price"
                :key="key"
                v-show="value && value.price"
              >
                <div class="title txt-bold">{{ memberLevelArr[index] }}</div>
                <div class="content txt-dark_green">{{ value.price || 0 }}元</div>
              </div>
            </div>
          </div>

          <div class="column_list" v-if="course.remark">
            <div class="main_title h4 txt-light_green txt-bold">備註</div>
            <div class="wrap">
              <div class="col100">
                <div class="content_group">
                  <div class="content txt-dark_green">
                    <p v-html="course.remark"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="course_list w1100">
      <table class="table" style="width:100%;min-width:800px">
        <thead>
          <tr class="small">
            <th class="cut10">{{ tableHead1 }}</th>
            <th class="txt-left">{{ tableHead2 }}</th>
            <th class="cut10">{{ tableHead3 }}</th>
            <th class="cut10" v-if="courseAvailable">{{ tableHead4 }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in course.course_videos" :key="item.uuid">
            <td :data-th="tableHead1"><p>{{ index + 1 }}</p></td>
            <td :data-th="tableHead2">
              <router-link
                :to="`/register/online/mycourse/video?
                course_id=${courseID}&unit=${index + 1}&video_id=${item.uuid}`"
                class="title"
                :class="{ active: courseAvailable }"
                @click.prevent="verifyCourseAvailable"
              >
                {{ item.name }}
              </router-link>
            </td>
            <td :data-th="tableHead3"><p>{{ item.length }}</p></td>
            <td :data-th="tableHead4" v-if="courseAvailable">
              <p :class="{ 'txt-red': isRed(item.course_user_video) }"
              >{{ unitStatus(item.course_user_video) }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="btn_wrap without_heart_btn"
      v-if="!(course.bought_completed === false || course.bought_completed === true)"
    >
      <!-- <a href="register_online_courses_index_grocery.php" class="btn">購買課程</a> -->
      <button
        type="button"
        class="btn dark_green-btn add p"
        @click="handleCartItem(courseID)"
      >
        <p>購買課程</p>
        <!-- <p v-show="!isActive(courseID)">購買課程</p>
        <p v-show="isActive(courseID)">移除清單</p> -->
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterOnlineSubHeader, fractionsName, memberLevel } from '@/lib/const';
// eslint-disable-next-line no-unused-vars
import { request, toFormData } from '@/boot/axios';
import { coursesView } from '@/lib/http';
import { expiryDate } from '@/lib/public';

export default {
  name: 'RegisterOnlineMycourseInfo',
  data() {
    return {
      ...RegisterOnlineSubHeader,
      ...fractionsName,
      ...memberLevel,
      courseID: '',
      courseAvailable: false,
      course: {
        // name: '乙類安寧居家療護 - 109年度社區安寧照護(乙類)醫護人員教育訓練',
        // episode: 10,
        // totalHours: 13,
        // tag: '',
        // credit: '',
        // expiration: '2020-08-31 11:15',
        // intro: '衛生福利部中央健康保險署目前積極推動分級醫療，
        // 鼓勵醫療體系間分工、合作，使民眾獲得最好的醫療照護；
        // 健保署臺北業務組推動「共好合作」概念，期藉由各醫療院所進行上、下游垂直整合，
        // 運用健保電子轉診平台及雲端醫療資訊之上傳與分享，落實雙向轉診合作。',
        // price: [
        //   {
        //     name: '會員',
        //     amount: 1000,
        //   },
        //   {
        //     name: '非會員醫師',
        //     amount: 2500,
        //   },
        //   {
        //     name: '護理人員',
        //     amount: 1600,
        //   },
        // ],
        // remark: `1.本課程不含見習部份。<br>
        //   2.要成為乙類安寧機構，護理師必須專任，醫師可以兼任。<br>
        //   3.學員全程參與將核發13小時研習證明，未完成課程將依實際狀況扣除時數核發證明。<br>
        //   4.本課程將申請衛生福利部醫事人員(西醫師)繼續教育分認定。`,
        // unit: [
        //   {
        //     id: 1,
        //     number: '01',
        //     name: '黃曉峰-末期病人的靈性需求',
        //     length: '1:00:04',
        //     status: '未通過',
        //   },
        //   {
        //     id: 2,
        //     number: '02',
        //     name: '黃曉峰-安寧療護臨床決策的倫理與困境',
        //     length: '1:38:55',
        //     status: '未通過',
        //   },
        //   {
        //     id: 3,
        //     number: '03',
        //     name: '蔡惠芳-末期病人之家屬照護及悲傷輔導之臨床實務運用',
        //     length: '2:02:11',
        //     status: '未通過',
        //   },
        //   {
        //     id: 4,
        //     number: '04',
        //     name: '蔡佩渝-安寧緩和療護的哲理、現況與展望',
        //     length: '1:02:11',
        //     status: '未通過',
        //   },
        //   {
        //     id: 5,
        //     number: '05',
        //     name: '蔡佩渝-末期疾病症狀評估與控制總論',
        //     length: '1:05:13',
        //     status: '未通過',
        //   },
        //   {
        //     id: 6,
        //     number: '06',
        //     name: '孫文榮-老人及十大末期疾病的安寧緩和療',
        //     length: '2:00:02',
        //     status: '未通過',
        //   },
        //   {
        //     id: 7,
        //     number: '07',
        //     name: '孫文榮-社區安寧緩和療護',
        //     length: '1:01:58',
        //     status: '未通過',
        //   },
        //   {
        //     id: 8,
        //     number: '08',
        //     name: '莊雅慧-出院準備與安寧居家療護',
        //     length: '1:01:37',
        //     status: '未通過',
        //   },
        //   {
        //     id: 9,
        //     number: '09',
        //     name: '黃佩菁-末期病人的心理社會需求',
        //     length: '1:09:54',
        //     status: '未通過',
        //   },
        // ],
      },
      tableHead1: '單元編號',
      tableHead2: '單元名稱',
      tableHead3: '單元長度',
      tableHead4: '狀態',
      componentKey: 0,
    };
  },
  created() {
    this.courseID = this.$route.query.course_id;
    coursesView({
      uuid: this.courseID,
    }).then((result) => {
      this.course = result.data;

      /** 已購買 */
      if (this.course.bought_completed === false
        || this.course.bought_completed === true
      ) {
        /** 有限期 */
        if (this.course.expiry_time && this.course.expiry_time > 0) {
          /** 未過期 */
          if (moment(this.course.bought_expiry_time).isAfter()) {
            this.courseAvailable = true;
          /** 已過期 */
          } else {
            this.courseAvailable = false;
          }
        /** 無限期 */
        } else {
          this.courseAvailable = true;
        }
      /** 未登入、未購買 */
      } else {
        this.courseAvailable = false;
      }
    });
  },
  computed: {
    computedExpiryTime() {
      /** 無期限 */
      if (!this.course.expiry_time) {
        return '無限制';
      }
      /** 課程已購買 */
      if (this.course.bought_completed === false || this.course.bought_completed === true) {
        return this.course.bought_expiry_time;
      }
      /** 課程未購買 */
      return `${this.course.expiry_days}天`;
    },
    isFractionExist() {
      const checkArray = [];
      if (this.course && this.course.fractions) {
        Object.keys(this.course.fractions).forEach((key) => {
          if (this.course.fractions[key] * 1 !== 0) {
            checkArray.push(true);
          }
        });
      }
      if (checkArray.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    verifyCourseAvailable(e) {
      if (!this.courseAvailable) {
        e.preventDefault();
      }
    },
    unitStatus(detail) {
      if (detail) {
        if (detail.pass * 1 === 1) {
          return '通過';
        }
        return '未通過';
      }
      return '未通過';
    },
    isRed(detail) {
      if (detail) {
        if (detail.pass * 1 === 1) {
          return false;
        }
        return true;
      }
      return true;
    },
    isActive(courseId) {
      const courseIdArray = JSON.parse(this.$cookies.get('courseInCart'));
      // console.log(courseIdArray);
      if (courseIdArray !== null) {
        if (courseIdArray.indexOf(courseId) !== -1) {
          return true;
        }
        return false;
      }
      return false;
    },
    handleCartItem(cartItemID) {
      const cookies = this.$cookies;
      let courseIdArray = [];

      if (cookies.get('courseInCart') !== null) {
        courseIdArray = JSON.parse(cookies.get('courseInCart'));
        /** toggle收藏清單功能 */
        if (courseIdArray.indexOf(cartItemID) !== -1) {
          /** 收藏清單內有該課程則清除 */
          // courseIdArray.splice(courseIdArray.indexOf(cartItemID), 1);
        } else {
          /** 收藏清單內無該課程則加入 */
          courseIdArray.push(cartItemID);
        }
        cookies.set('courseInCart', JSON.stringify(courseIdArray));
      } else {
        courseIdArray.push(cartItemID);
        cookies.set('courseInCart', JSON.stringify(courseIdArray));
      }
      // console.log(JSON.parse(cookies.get('courseInCart')));
      this.forceRerender();
      this.$forceUpdate();
      this.$router.push({ path: '/register/online/grocery' });
    },
    /**
     * Force PageSubheader To Update Its Count
     */
    forceRerender() {
      this.componentKey += 1;
    },
    expiryDate,
  },
  components: {
    PageSubHeader,
  },
};
</script>
